.HeadCoupon{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 25px;
}
.HeadCoupon_title{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 50px;
}
.radiousLogo{
    border-radius: 100px;
    border: 2px solid #3D5E46;
    width: 185px;
    height: 185px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.radiousLogo img{
    max-width: 100%;
    object-fit: contain;
}
.HeadCoupon_title h1{
    color: #000;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 125%; /* 50px */
    margin: 0;
}
.HeadCoupon_title h1 span{
    color: #EC0000;
}
.HeadCoupon_description p{
    margin: 0;
    color: #011B3C;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 28px */
    max-width: 918px;
}
@media(max-width:767px){
    .radiousLogo{
        width: 129px;
        height: 129px;
    }
    .HeadCoupon_title{
        gap: 20px;
    }
    .HeadCoupon_title h1{
        font-size: 24px;
    }
    .HeadCoupon_description p{
        font-size: 16px;
    }
}